<template>
	<div class="flex-1 bg-white pa-24 radius-20">

		<div class="under-line-bbb pb-16 flex-row">
			<h3 class="flex-1 size-px-20">카르텔 통계</h3>
		</div>
		<!-- 탭선택 -->
		<div class="mt-20">
			<div class="flex-row justify-space-between ">
				<div>
					<button
						:class="{'btn-primary': type == 'stat'}"
						@click="type = 'stat'"
						class="btn-inline btn-gray radius-20 mr-10 pa-10-30"
					>통계</button>
					<button
						:class="{'btn-primary': type == 'member'}"
						@click="type = 'member'"
						class="btn-inline btn-gray radius-20 mr-10 pa-10-30"
					>우수멤버</button>
				</div>
				<div
					class="position-relative"
				>
					<div
						class="position-absolute"
						style=" right: 0px; top: 0"
					>
						<div class="flex-column radius-20" style="width: 100px; border: 1px solid #dadada"
							:class="{ date_btn:is_on_period }"
						>
							<button
								v-if="is_on_period || item_search.stat_division == 'daily'"

								class="text-left size-px-14 color-333"
								style="padding: 7px 0px 7px 20px"
								@click="setDivision('daily')"
							>일간</button>
							<button
								v-if="is_on_period || item_search.stat_division == 'weekly'"
								@click="setDivision('weekly')"
								class="text-left size-px-14 color-333"
								style="padding: 7px 0px 7px 20px; border-top: 1px solid #dadada; border-bottom: 1px solid #dadada"
							>주간</button>
							<button
								v-if="is_on_period || item_search.stat_division == 'monthly'"
								@click="setDivision('monthly')"
								class="text-left size-px-14 color-333"
								style="padding: 7px 0px 7px 20px"
							>월간</button>

							<v-icon
								v-if="!is_on_period"
								class="position-absolute" style="right: 10px; top: 7px"
								@click="is_on_period = true"
							>mdi-chevron-down</v-icon>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="mt-10">
			<div class="flex-row justify-center items-center gap-20">

				<button
					@click="prev"
					class="btn_arrow"
				><i class="icon-arrow-left"></i></button>
				<!-- TODO : 프론트작업요청 -->

				<span
					@click="is_picker = !is_picker"
				>
					{{ text_picker }}

					<img
						class="ml-5"
						:src="require('@/assets/image/web/setting_cartel/ico_calendar.svg')">
				</span>

				<PopupLayer
					v-if="is_picker"
				>
					<template
						v-slot:body
					>
						<div
							class=" ma-auto width-320 radius-20"
						>
							<v-date-picker
								v-if="item_search.stat_division == 'daily'"
								v-model="item_search.date"
								no-title
								scrollable
								locale="ko-KR"
								@change="checkDate"
							></v-date-picker>

							<v-date-picker
								v-if="item_search.stat_division == 'weekly'"
								v-model="item_search.w_date"
								no-title
								scrollable
								range
								locale="ko-KR"
								@change="checkDate"
							></v-date-picker>

							<v-date-picker
								v-if="item_search.stat_division == 'monthly'"
								v-model="item_search.monthly"
								no-title
								scrollable
								type="month"
								locale="ko-KR"
								@change="checkDate"
							></v-date-picker>

							<button
								@click="is_picker = false;"
								class="btn btn-primary"
							>확인</button>
						</div>
					</template>
				</PopupLayer>

				<button
					@click="next"
					class="btn_arrow"
				><i class="icon-arrow-right"></i></button>
			</div>
			<div
				v-if="type == 'stat'"
				class="mt-20"
			>
				<table class="table">
					<thead>
					<tr class="t_head">
						<th class="t_tit">방문자수</th>
						<th class="t_tit">가입수</th>
						<th class="t_tit">새글수</th>
					</tr>
					</thead>
					<tbody>
					<tr class="t_body">
						<td class="t_conut">{{ item_stats.visit_count }}</td>
						<td class="t_conut">{{ item_stats.join_count }}</td>
						<td class="t_conut">{{ item_stats.new_count }}</td>
					</tr>
					</tbody>
				</table>
			</div>
			<!-- //스케줄 검색 -->
			<!-- 그래프탭 영역 -->
			<div
				v-if="type == 'stat'"
				class="mt-20"
			>
				<div class="under-line">
					<ul class="flex-row">
						<!-- TODO : 탭 선택시 on 추가 -->
						<li
							:class="{on: item_search.stat_type == 'visit'}"
							@click="setType('visit')"
							class="pa-10"
						><span class="t_line"></span>방문자 수</li>
						<li
							:class="{on: item_search.stat_type == 'join'}"
							@click="setType('join')"
							class="pa-10"
						><span class="t_line"></span>가입자 수</li>
						<li
							:class="{on: item_search.stat_type == 'new'}"
							@click="setType('new')"
							class="pa-10"
						><span class="t_line"></span>새글 수</li>
					</ul>
				</div>
				<div class="mt-20 flex-row justify-space-between items-center">
					<span class="ch_date font-weight-600 color-333">{{  item_search.s_date }}</span>
					<span class="ch_value size-px-16 font-weight-700 color-primary">{{  item_stats[item_search.stat_type + '_count'] }}</span>
				</div>
				<div class="mt-16">
					<LineChartGenerator
						:chart-options="chartOptions"
						:chart-data="chartData"
					/>
				</div>
			</div>

			<!-- //그래프탭 영역 -->
			<div
				v-if="type == 'member'"
				class="ct_list_wrap subindex_item"
			>
				<div class="container">
					<div class="row">
						<!-- 우수멤버 리스트 -->
						<div
							v-if="items_stat_member.length > 0"
							class="thumbnail_list ct_best"
						>
							<!-- 베스트3 -->
							<div
								v-for="(item, index) in items_stat_member"
								:key="'item_' + index"
								class="ct_bestmember_list"
							>
								<div
									class="circle_thumbnail_item"
								>
									<div
										class="clear " :class="{ top_3: index < 3}"
									>
										<div class="picture">
											<img
												v-if="item.img_src"
												:src="$request.upload_url(item.img_src)"
											/>
											<img
												v-lese
												:src="require('@/assets/image/@noimage2.png')"
											/>
										</div>
										<span class=" level" :class="{best_level: index == 0}"><em class="hide">{{ item.user_rank }}</em></span>
										<div class="text_area">
											<strong>{{ item.user_nick }}</strong>
											<p class="textzone">{{  item.user_level }}</p>
										</div>
									</div>
									<div class="ct_detail_desc">
										<div>
											<div class="info"><span class="tit">방문</span><em>{{  item.visit_count }}</em></div>
											<div class="info pt-8"><span class="tit">글등록</span><em>{{  item.new_count }}</em></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							v-else
						>
							<div class="list_none flex-column align-center gap-15" style="overflow: hidden; margin-top: 50%">
								<img :src="require('@/assets/image/list_none.png')" alt="이력없음">
								<span class="size-px-14 color-bbb">우수멤버가 없습니다.</span>
							</div>
						</div>
						<!-- //우수멤버 리스트 -->
					</div>
				</div>
			</div>
		</div>
		<!-- //조회 -->
	</div>
</template>

<script>

	import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

	import {
		Chart as ChartJS,
		Title,
		Tooltip,
		Legend,
		LineElement,
		LinearScale,
		CategoryScale,
		PointElement
	} from 'chart.js'
	import PopupLayer from "@/view/Layout/PopupLayer";

	ChartJS.register(
		Title,
		Tooltip,
		Legend,
		LineElement,
		LinearScale,
		CategoryScale,
		PointElement
	)

	export default {
		name: 'mafia0911'
		, props: ['user']
		, components: {PopupLayer, LineChartGenerator }
		, data: function(){
			return {
				program: {
					name: '카르텔 통계'
					, title: '카르텔 통계'
					, not_header: true
					, not_footer: true
					, type: 'cartel_sub'
					, bg_contents: 'bg-gray01'
					, is_side: true
					, cartel: ''
				}
				, type: 'stat'
				, item_cartel: {}
				, item_stats: {
					visit_count: 1
					, join_count: 2
					, new_count: 3
				}
				, items_stat_member: [

				]
				, items_stat: []
				, is_picker: false
				, item_search: this.$storage.init({
					stat_division: 'daily'
					, stat_type: 'visit'
					, daily: this.$date.getToday('-')
					, monthly: this.$date.init('', '-').year + '-' + this.$date.init().month
					, weekly: this.$date.init('', '-').year + '-' + this.$date.init('', '-').weeks
					, s_date: this.$date.getWeeklyDate('', '-').start
					, e_date: this.$date.getWeeklyDate('', '-').end
					, w_date: []

				})
				, chartData: {
					labels: []
					, datasets:[
						{
							label: 'Data One'
							, backgroundColor: '#f87979'
							, data: []
							, showLabel: false
							, pointStyle: 'circle'
							, pointColor: 'red'
							, options: {
								legend: {
									display: false //This will do the task
								}
							}
						}
					]
				},
				chartOptions: {
					responsive: true,
					maintainAspectRatio: true,
					legend: {
						display: false
					}
				}
				, is_on_period: false
			}
		}
		, computed: {
			text_picker: function(){
				let t = this.item_search[this.item_search.stat_division]
				if(this.item_search.stat_division == 'weekly'){
					t = this.item_search.s_date + ' ~ ' + this.item_search.e_date
				}
				return t
			}
		}
		, methods: {
			prev: function () {
				switch (this.item_search.stat_division) {
					case 'daily':
						this.item_search.daily = this.$date.getPrevDay(this.item_search.daily, '-')
						break
					case 'weekly':
						this.item_search.s_date = this.$date.getPrevWeelkyDate(this.item_search.s_date, '-').start
						this.item_search.e_date = this.$date.getWeeklyDate(this.item_search.s_date, '-').end
						this.item_search.weekly = this.$date.init(this.item_search.s_date, '-').fullWeeks
						break
					case 'monthly':
						this.item_search.monthly = this.$date.getPrevMonth(this.item_search.monthly + '-01', '', '-').fullMonth
						break
				}

				this.getData()
			}
			, next: function () {
				let is_do = false
				switch (this.item_search.stat_division) {
					case 'daily':
						if(this.$date.getToday('-') >= this.$date.getNextDay(this.item_search.daily, '-')){
							is_do = true
							this.item_search.daily = this.$date.getNextDay(this.item_search.daily, '-')
						}
						break
					case 'weekly':
						if(this.$date.getToday('-') > this.item_search.e_date) {
							this.item_search.s_date = this.$date.getNextWeelkyDate(this.item_search.e_date, '-').start
							this.item_search.e_date = this.$date.getWeeklyDate(this.item_search.s_date, '-').end
							this.item_search.weekly = this.$date.init(this.item_search.s_date, '-').fullWeeks
							is_do = true
						}

						break
					case 'monthly':
						if(this.$date.getToday('-').slice(0, 7) > this.item_search.monthly) {
							this.item_search.monthly = this.$date.getNextMonth(this.item_search.monthly + '-01', '', '-').fullMonth
							is_do = true
						}
						break
				}

				if(is_do){
					this.getData()
				}
			}
			, setDivision: function (type) {
				this.is_on_period = false
				this.item_search.stat_division = type
				this.getData()
			}
			, setType: function (type) {
				this.item_search.stat_type = type
				this.getData()
			}
			, setWdate: function(e){
				this.item_search.s_date = this.$date.getWeeklyDate(this.item_search.w_date[0], '-').start
				this.item_search.e_date = this.$date.getWeeklyDate(this.item_search.w_date[0], '-').end
				this.item_search.w_date = [this.item_search.s_date, this.item_search.e_date]
				this.$log.console('setWdate', e)
			}
			, setLabel: function(){
				let data = []
				let start = ''
				switch (this.item_search.stat_division){
					case 'daily':
						start = this.$date.getNextDay(this.item_search.daily, '-')
						for(let i = 0; i < 7; i++){
							this.$log.console(i, start)
							data.unshift(this.$date.getPrevDay(start, '-').slice(-2) + '일')
							start = this.$date.getPrevDay(start, '-')
							this.$log.console('start', start)
						}
						break
					case 'weekly':
						start = this.$date.getNextWeelkyDate(this.item_search.s_date, '-').start
						for(let i = 0; i < 7; i++){
							this.$log.console('weekly ' + i, start)
							data.unshift(this.$date.getPrevWeelkyDate(start, '-').fullWeeks + '주')
							start = this.$date.getPrevWeelkyDate(start, '-').start
						}
						break
					case 'monthly':
						start = this.$date.getNextMonth(this.item_search.monthly, 1, '-').fullMonth
						for(let i = 0; i < 7; i++){
							this.$log.console(i, start)
							data.unshift(this.$date.getPrevMonth(start + '-01', '', '-').fullMonth)
							start = this.$date.getPrevMonth(start + '-01', '', '-').fullMonth
							this.$log.console('start', start)
						}
						break
				}
				this.$log.console('label', data)
				this.chartData.labels = data
			}
			, checkDate: function(){
				switch (this.item_search.stat_division){
					case 'daily':
						if(this.$date.getToday('-') > this.item_search.date){
							this.item_search.daily = this.item_search.date
						}
						break
					case 'weekly':
						break
					case 'monthly':
						if(this.item_search.monthly > this.$date.getToday('-').slice(0, 7)) {
							this.item_search.monthly = this.$date.getToday('-').slice(0, 7)
						}
						break
				}
			}
			,getCartel: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
						this.program.cartel = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getCartel()
			this.getData()
		}
		, watch: {
			'item_search.w_date': {
				handler: function(){
					if(this.item_search.w_date[0] == this.item_search.s_date){
						return false
					}
					let s;
					let e;
					if(this.item_search.w_date[0] > this.$date.getToday('-')){
						s = this.$date.getWeeklyDate(this.$date.getToday('-'), '-').start
						e =this.$date.getWeeklyDate(this.$date.getToday('-'), '-').end
					}else{
						s = this.$date.getWeeklyDate(this.item_search.w_date[0], '-').start
						e =this.$date.getWeeklyDate(this.item_search.w_date[0], '-').end
					}
					this.$log.console('item_search.w_date')
					this.item_search.w_date = [s, e]

					this.item_search.s_date = s
					this.item_search.e_date = e
					this.item_search.weekly = this.$date.init(s, '-').fullWeeks
				}
			}
			, item_search: {
				deep: true
				, handler: function (call){
					this.$log.console('call', call)
				}
			}
		}
	}
</script>

<style>
	.v-date-picker-table .v-btn.v-btn--active { color: black !important;}
	.v-date-picker-header__value button { font-size: 18px !important;}

	.table{
		border-top: 1px solid #e9e9e9;
		
	}
	.t_tit{
		font-size: 14px;
		font-weight: 400;
		color: var(--dark-Gray02);
		border-bottom: 1px solid #e9e9e9;
	}
	.t_conut{
		font-size: 16px;
		font-weight: 700;
		color: var(--blue02);
		border-bottom: 1px solid #e9e9e9;
	}

	.date_btn{
		border-radius: 10px;
	}
	.date_btn button{
		transition: .2s all;
		background-color: #f7f7f7;
	}

	.date_btn button:hover{
		background-color: #fff;
	}
</style>